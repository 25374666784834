import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Admin } from './Pages/Admin';
import { Homepage } from './Pages/Homepage';
import { ItemPage } from './Pages/ItemPage';
import { Login } from './Pages/Login';

const AllRoutes = () => {
    return (
        <div className='w-full h-min-screen'>
            <Routes>
                <Route path='/' element={<Homepage />}/>
                <Route path="/card/:id" element={<ItemPage />}/>
                <Route path='/login' element={<Login />}/>
                <Route path='/admin' element={<Admin />}/>
            </Routes>
        </div>
    )
}

export { AllRoutes }