import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { GrLinkPrevious } from "react-icons/gr";
import { getCards } from '../../firebase-config';
import { setCards } from '../../Redux/app/action';

const ItemPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [item, setItem] = useState({});
    const { list } = useSelector(state => state.app);
    const [loading, setLoading] = useState(false);

    const getCardData = async (e) => {
        setLoading(true);
        if (list.length <= 0) {
            let tempList = await getCards();
            dispatch(setCards(tempList))
            tempList.forEach(el => {
                if (el.id === id) {
                    setItem(el);
                    setLoading(false);
                    return;
                }
            });
        } else {
            list.forEach(el => {
                if (el.id === id) {
                    setItem(el);
                    setLoading(false);
                    return;
                }
            });
        }

        setLoading(false);
    }

    useEffect(() => {
        window.scrollTo(0,0);

        getCardData()
    }, []);
    return (
        <div className='bg-slate-200 flex justify-center p-10 min-h-[90vh]'>
            <div className='flex items-center fixed top-[100px] left-[50px] cursor-pointer' onClick={() => navigate("/")}>
                 <GrLinkPrevious className="m-2 "/> GO BACK
            </div>
            {
                loading === true && <p className='fixed center text-2xl font-bold'>Loading ...</p>
            }
            <div className='w-1/2 bg-slate-50 rounded-md p-4 drop-shadow'>
                <p className='text-3xl font-bold'>{item.name}</p>
                <p className='my-2 w-auto inline-block'>{item.description}</p>
                <div className='f-fit flex flex-col items-center px-8'>
                    {
                        item.list?.length > 0 &&
                        item.list.map((item, index) => {

                            // if(index > 2){
                            //     return;
                            // } else if (index === 2){
                            //     return (
                            //         <Link key={index} to={`/card/${id}`}  className='w-fit text-blue-600 hover:text-blue-800 cursor-pointer flex items-center font-bold'>+{(list.length - 1) - index} More  <FiExternalLink className='text-xl mx-2'/></Link>
                            //     )
                            // }

                            return (
                                <div key={index} className="m-3 w-full">
                                    <p className='text-xl font-bold'>{item.name}</p>
                                    {
                                        item.link !== "" && <a href={item.link} target="blank" className='text-blue-600 hover:text-blue-800'>{item.link}</a>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export { ItemPage }