import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteCard, getCards, saveCard, updateCard } from '../../firebase-config';
import { loadData } from '../../Utils/localStorage';
import { toast } from 'react-toastify';
import { MdDelete } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";

const Admin = () => {
    const navigate = useNavigate();
    const [editType, setEditType] = useState("update");
    const [cardData, setCardData] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState("");
    const [cardList, setCardList] = useState([]);
    const [cardLoading, setCardLoading] = useState(false)
    const [modal, setMoodal] = useState(false)
    const [fileId, setFileId] = useState("");

    const sample = {
        "id": "",
        "name": "",
        "description": "",
        "tags": [],
        "list": [
            {
                "name": "",
                "link": ""
            }
        ]
    }

    let localAuth = loadData("isAuth");

    const fetchCards = async () => {
        setCardLoading(true)
        let list = await getCards();
        console.log("list: ", list);
        setCardList(list);
        setCardLoading(false)
    }

    const handleTypeChange = async (para) => {
        setEditType(para)
        setCardData({})
        setFile("")
        setFileName("")
        setLoaded(false)
        setCardList([])

        if (para === "update") {
            await fetchCards()
        }
    }

    const downloadFile = (data, fileName = "card.json") => {
        let jsonData = JSON.stringify(data);

        let a = document.createElement("a");
        let file = new Blob([jsonData], { type: "json" });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }


    const uploadFile = async (e) => {
        if (e.target.files[0] === undefined) {
            return;
        } else {
            setFileName(e.target.files[0].name)
        }

        let data;
        await new Response(e.target.files[0]).json().then(json => {
            data = json;
            console.log("data: ", data);
        }, err => {
            // not json
            toast.error("Not a json file")
        })

        if (data !== undefined) {
            setCardData(data)
            console.log(data);
            toast("File loaded...")
            setLoaded(true)
        }
    };

    const initiateDelete = (para) => {
        setMoodal(true)
        setFileId(para)
    }

    const cancelDelete = () => {
        setMoodal(false)
        setFileId("")
    }

    const handleDelete = async () => {
        await deleteCard(fileId)
        setFileId("")
        setMoodal(false)
        await fetchCards()
    }

    const handleSubmit = async () => {
        if (loaded === false) {
            toast.error("File not loaded")
            return;
        }

        await saveCard(cardData)
        setLoaded(false)
        setCardData({})
        setFileName("")
        setFile("")
    }

    const handleUpdate = async () => {
        if (loaded === false) {
            toast.error("File not loaded")
            return;
        }

        await updateCard(cardData.id, cardData)
        setLoaded(false)
        setCardData({})
        setFileName("")
        setFile("")
        await fetchCards()
    }

    useEffect(() => {
        if (localAuth === false || localAuth === null) {
            navigate("/")
        } else {
            fetchCards()
        }
    }, []);

    return (
        <div className='min-h-screen max-h-screen flex '>
            <div className='w-4/12 flex flex-col items-center bg-[white]'>
                <div className='w-full'>
                    {
                        editType === "create" ?
                            <>
                                <button className='w-1/2 text-xl p-2 underline'>Create</button>
                                <button className='w-1/2 text-xl p-2 bg-slate-200' onClick={() => handleTypeChange("update")}>Update</button>
                            </>
                            :
                            <>
                                <button className='w-1/2 text-xl p-2 bg-slate-200' onClick={() => handleTypeChange("create")}>Create</button>
                                <button className='w-1/2 text-xl p-2 underline'>Update</button>
                            </>
                    }
                </div>

                {
                    editType === "create" ?
                        <>
                            <button className='bg-blue-500 p-2 rounded-md text-slate-100 w-60 m-4 text-xl' onClick={() => downloadFile(sample)}>Sample File</button>
                            <button className='bg-blue-500 p-2 rounded-md text-slate-100 w-60 m-4 text-xl relative'>Upload File <input value={file} type="file" className="absolute left-0 top-0 w-60 cursor-pointer p-[2px] opacity-0" onChange={(e) => uploadFile(e)} /></button>
                            <p>{fileName}</p>
                        </>
                        :
                        <>
                            <button className='bg-blue-500 p-2 rounded-md text-slate-100 w-60 m-4 text-xl relative'>Upload File <input value={file} type="file" className="absolute left-0 top-0 w-60 cursor-pointer p-[2px] opacity-0" onChange={(e) => uploadFile(e)} /></button>
                            <p>{fileName}</p>
                        </>
                }

                <div className='border-slate-400 border-b-2 m-4 w-full' />

                {
                    editType === "create" ?
                        <button className='bg-blue-500 p-2 rounded-md text-slate-100 w-60 my-4 text-xl' onClick={() => handleSubmit()}>
                            Submit
                        </button>

                        :
                        <button className='bg-blue-500 p-2 rounded-md text-slate-100 w-60 my-4 text-xl' onClick={() => handleUpdate()}>
                            Update
                        </button>
                }

                <div className='w-full grow overflow-scroll scroll-auto'>
                    {
                        cardLoading === true && <p className='font-bold text-2xl text-center'>Loading...</p>
                    }
                    {
                        cardList.length > 0 && cardLoading === false &&
                        cardList.map((item, index) => {
                            return (
                                <div className='p-2 w-full flex items-start border-slate-400 border-2' key={index}>
                                    <p className='w-3/4'>{item.name}</p>
                                    <label className='flex'>
                                        <AiOutlineDownload className='text-4xl mx-2 cursor-pointer text-green-600' onClick={() => downloadFile(item, `${item.id}.json`)} />
                                        <MdDelete className='text-4xl mx-2 cursor-pointer text-red-600' onClick={() => initiateDelete(item.id)} />
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='w-9/12 flex flex-col items-center p-10'>
                {
                    loaded === true &&
                    <div className='bg-slate-50 p-10 rounded-md w-1/2'>
                        <p className='text-3xl font-bold'>{cardData.name}</p>
                        <p className='my-2'>{cardData.description}</p>
                        {
                            cardData.list.length > 0 &&
                            cardData.list.map((item, index) => {
                                return (
                                    <div key={index} className="m-3">
                                        <p className='text-xl font-bold'>{item.name}</p>
                                        {
                                            item.link !== "" && <a href={item.link} target="blank" className='text-blue-600'>{item.link}</a>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
            {
                modal === true &&
                <div className='fixed center bg-slate-50 rounded p-4'>
                    <p className='m-2 mb-6'>Do you really want to delete ??</p>
                    <div className='flex justify-center'>
                        <button className='bg-red-500 w-24 p-2 m-1 rounded text-slate-100' onClick={() => handleDelete()}>Yes</button>
                        <button className='bg-blue-500 w-24 p-2 m-1 rounded text-slate-100' onClick={() => cancelDelete()}>No</button>
                    </div>
                </div>
            }
        </div>
    )
}

export { Admin }