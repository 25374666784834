import React, { useEffect } from 'react';
import './App.css';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { AllRoutes } from './Routes/AllRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadData, saveData } from "./Utils/localStorage";
import { isValid } from './Utils/utils';
import { user } from "./Utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from './Redux/app/action';
// import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyD0OcWwCbPo-WvakBa3Sv_Jph5vQYRtMmE",
//   authDomain: "resource-hub-3bb86.firebaseapp.com",
//   projectId: "resource-hub-3bb86",
//   storageBucket: "resource-hub-3bb86.appspot.com",
//   messagingSenderId: "727269825571",
//   appId: "1:727269825571:web:b3cb4d063c6a597e216596"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

function App() {
  const dispatch = useDispatch();

  const handleAuth = () => {
    let value = loadData("isAuth")

    if (!isValid(value)) {
      saveData("isAuth", false)
    } else {
      // value exist
      if (value === true) {
        dispatch(setAuth(true))
        saveData("isAuth", true)
      } else {
        dispatch(setAuth(false))
        saveData("isAuth", false)
      }
    }
  }

  useEffect(() => {
    handleAuth()
  }, []);

  return (
    <div className="App min-h-screen flex flex-col items-center relative bg-slate-200">
      <Header />
      <AllRoutes />
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
