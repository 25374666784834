const waitFor = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const isValid = (para) => {
    let status;

    if(para === null || para === undefined || para === ""){
        status = false;
    } else {
        status = true;
    }

    return status;
}

export { waitFor, isValid }