import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { waitFor } from "../../Utils/utils";
import { user } from "../../Utils/auth";
import { BiLoaderAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from '../../Redux/app/action';
import { loadData, saveData } from "../../Utils/localStorage";
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const { isAuth } = useSelector(state => state.app)
    const localIsAuth = loadData("isAuth");

    const handleKeypress = (e) => {
        if (e.code == "Enter") {
            handleLogin();
        }
    }

    const handleLogin = async () => {
        setLoading(true)
        await waitFor(700);

        function resetStates() {
            setLoading(false)
            setUsername("")
            setPassword("")

            setVisible(true)
            setTimeout(() => {
                setVisible(false)
            }, 5000);
        }

        if (user.username === username && user.password === password) {
            if (visible === false) {
                toast("Login successful !!");
                saveData("isAuth", true);
                dispatch(setAuth(true));
                resetStates();
                navigate("/admin")
            }
        } else {
            if (visible === false) {
                toast.error("Login Failed !!");
                resetStates();
            }
        }
    }

    useEffect(() => {
        if(isAuth === true || localIsAuth === true){
            navigate("/")
        }
    }, []);

    return (
        <div className='h-[90vh] w-full flex items-center justify-center'>
            <div className='w-[400px] bg-slate-50 shadow-sm rounded-md flex flex-col p-12'>
                <div className='flex flex-col mb-8'>
                    <label className='text-lg'>Username</label>
                    <input
                        className='bg-slate-50 border-slate-200 border-2 p-2 rounded-md'
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        onKeyPress={(e) => handleKeypress(e)}
                    />
                </div>
                <div className='flex flex-col mb-8'>
                    <label className='text-lg'>Password</label>
                    <input
                        className='bg-slate-50 border-slate-200 border-2 p-2 rounded-md'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        onKeyPress={(e) => handleKeypress(e)}
                    />
                </div>
                <button
                    className='bg-blue-400 p-2 rounded-md text-xl text-slate-100 flex justify-center items-center'
                    onClick={() => handleLogin()}
                    disabled={loading}
                >
                    Login
                    {loading === true && <BiLoaderAlt className='animate-spin mx-4' />}
                </button>
            </div>
        </div>
    )
}

export { Login }