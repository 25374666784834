import React from 'react';
import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";

const ItemCard = ({data}) => {
    const { name, description, list, id } = data;

    return (
        <div className='bg-slate-50 p-10 rounded-md w-fit h-fit m-4 drop-shadow'>
            <p className='text-3xl font-bold'>{name}</p>
            <p className='my-2 w-auto inline-block'>{description}</p>
            <div className='max-h-[250px] flex flex-col items-center'>
            {
                list.length > 0 &&
                list.map((item, index) => {

                    if(index > 2){
                        return;
                    } else if (index === 2){
                        return (
                            <Link key={index} to={`/card/${id}`}  className='w-full text-blue-600 hover:text-blue-800 cursor-pointer flex items-center justify-end font-bold'>+{(list.length - 1) - index} More  <FiExternalLink className='text-xl mx-2'/></Link>
                        )
                    }

                    return (
                        <div key={index} className="m-3 w-full">
                            <p className='text-xl font-bold'>{item.name}</p>
                            {
                                item.link !== "" && <a href={item.link} target="blank" className='text-blue-600 hover:text-blue-800'>{item.link}</a>
                            }
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}

export { ItemCard }