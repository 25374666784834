import { SET_AUTH, SET_CARDS } from './actionType'

const initialState = {
    isAuth: false,
    list: []
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_AUTH:
            return {
                ...state,
                isAuth: payload
            }
        case SET_CARDS:
            return {
                ...state,
                list: [...payload]
            }
        default:
            return state
    }
}

export { reducer }