import React from 'react';

const Footer = () => {
    return (
        <div className='w-full h-16 bg-slate-300 flex items-center justify-center'>
            Made in ❤️ with Code 
        </div>
    )
}

export { Footer }