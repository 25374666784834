import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, updateDoc, addDoc, deleteDoc, doc } from 'firebase/firestore/lite';
import { toast } from 'react-toastify';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Get a list of cards from database
export async function getCards() {
  const cardCollection = collection(db, 'card_list');
  const cardSnapshot = await getDocs(cardCollection);
  const cardList = cardSnapshot.docs.map(doc => doc.data());
  return cardList;
}

export async function saveCard(data) {
  try {
    let docRef = await addDoc(collection(db, "card_list"), data);
    await updateDoc(docRef, { id: docRef.id });
    toast("Card Added...")
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong...")
  }
}

export async function deleteCard(id) {
  try {
    await deleteDoc(doc(db, "card_list", id));
    toast("Card Deleted...")
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong...")
  }
}

export async function updateCard(id, data) {
  try {
    const cardRef = doc(db, "card_list", id);
    await updateDoc(cardRef, data);
    toast("Card Updated...")
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong...")
  }
}