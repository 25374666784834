import { SET_AUTH, SET_CARDS } from '../app/actionType'

const setAuth = (payload) => {
    return {
        type: SET_AUTH,
        payload
    }
}

const setCards = (payload) => {
    return {
        type: SET_CARDS,
        payload
    }
}


export {
    setAuth,
    setCards
}