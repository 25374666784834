import React, { useState, useEffect } from 'react';
import { ItemCard } from '../../components/ItemCard';
import { Sidebar } from "../../components/Sidebar";
import { getCards } from '../../firebase-config';
import { useSelector, useDispatch } from 'react-redux';
import { setCards } from '../../Redux/app/action';

const Homepage = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [filterCardList, setFilterCardList] = useState([]);

    const { list } = useSelector((state) => state.app);

    const fetchCards = async () => {
        setLoading(true)
        let list = await getCards()
        // setCardList(list)
        dispatch(setCards(list))
        setLoading(false)
    }

    useEffect(() => {
        if(list.length <= 0){
            fetchCards()
        } else {
            
        }
    }, []);

    return (
        <div className='min-h-screen flex relative justify-center'>
            {/* <Sidebar /> */}
            <div className='flex flex-wrap relative py-12 justify-center'>
            {
                loading === true && <p className='fixed center text-2xl font-bold'>Loading ...</p>
            }
            {
                loading === false && 
                list.length > 0 &&
                list.map((item, index) => {
                    return (
                        <ItemCard data={item} key={index}/>
                        )
                    })
                }
            </div>
        </div>
    )
}

export { Homepage }